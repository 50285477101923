<template>
  <CModal
    @update:show="closeModal"
    title="Hotel Edit"
    color="primary"
    :show.sync="showModal"
    centered
    @click.
  >
    <CInput label="Name" placeholder="Enter your name" v-model="item.name" />
    <CInput
      label="Address"
      placeholder="Enter your address"
      v-model="item.address"
    />
    <CSelect
      label="Tariff"
      :options="selectOptions"
      :value.sync="item.tariff_id"
    />
    <div class="form-group">
      <label>Subscription end</label>
      <date-picker
        :disabledDates="disabledDates"
        format="yyyy-MM-dd"
        v-model="date"
        valueType="format"
        inputClass="form-control"
      ></date-picker>
    </div>
  </CModal>
</template>

<script>
import { CModal, CInput, CSelect } from "@coreui/vue/src";
import DatePicker from "vuejs-datepicker";

export default {
  name: "HotelChangeModal",
  props: ["show", "item"],
  data() {
    return {
      showModal: false,
      selectOptions: [
        { value: 1, label: "Free" },
        { value: 2, label: "Standart" },
        { value: 3, label: "Profi" },
      ],
      date: null,
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
    };
  },
  watch: {
    show: function (value) {
      this.showModal = value;
    },
  },
  methods: {
    closeModal(show, event, accept) {
      if (accept) {
        this.item.tariff_subscription_date_end = this.date
          .toISOString()
          .slice(0, 10);
        this.$emit("accept");
      } else {
        this.$emit("close");
      }
    },
  },
  components: {
    CModal,
    CInput,
    CSelect,
    DatePicker,
  },
};
</script>

<style scoped></style>
